<template>
  <div class="page-box">
    <a-spin :spinning="headLoading">
      <a-row class="page-head">
        <a-col :span="12" class="head-item">
          <p class="label">
            已结算金额（元）
            <a-button type="link" @click="goRecordPage" v-pms="'withdrawalRecord'" style="padding:0">结算记录</a-button>
          </p>
          <div class="withdrawal-box">
            <span class="value">{{shopAccountInfo.cashAmount}}</span>
            <!-- <a-button v-pms="'withdrawal'" @click="withdrawal" :disabled="shopAccountInfo.cashAmount<=0">提现</a-button> -->
          </div>
        </a-col>
        <a-col :span="12" class="head-item">
          <p class="label">待结算金额（元）</p>
          <div class="value">{{shopAccountInfo.uncashAmount}}</div>
        </a-col>
      </a-row>
    </a-spin>
    <BaseSubTitle title="最近交易记录" />
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="订单号" prop='orderNo'>
          <a-input v-model="params.orderNo" placeholder="请输入订单号查询" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-table :columns="columns" bordered :dataSource="tableData" :rowKey="record => record.shopAccountChangeLogId" :loading="tableLoading" :pagination='pagination' @change="handleTableChange">
      <template slot="orderNoAndPayOrderNo" slot-scope="text,record">{{record.orderNo}}|{{record.payOrderNo}}</template>
    </a-table>
    <a-modal v-model="authVisible" title="提现" okText="提交" :confirm-loading="authModalConfirmLoading" @cancel='modalFormRef.resetFields()' @ok="authFn">
      <a-spin :spinning="authModalLoading">
        <a-form-model ref="modalFormRef" :model="modalFormData" @submit.native.prevent :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
          <a-form-model-item label="店铺名称：">{{$store.getters['head/getShopInfo'].name}}</a-form-model-item>
          <a-form-model-item label="可提现金额：">
            <span>{{shopAccountInfo.cashAmount}}元</span>
          </a-form-model-item>
          <a-form-model-item label="本次提现：" prop="cashAmount">
            <a-row>
              <a-col :span="16">
                <a-input-number @change='changeAmount' :disabled="shopAccountInfo.cashAmount<1" :min="1" :max="Number(shopAccountInfo.cashAmount)" :precision="2" placeholder="最少提现金额为1元" style="width:100%" v-model="modalFormData.cashAmount" />
              </a-col>
              <a-col :span="8">
                <a-button type="link" @click='getAll'>全部提现</a-button>
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item label="到账银行卡：" prop='shopBankCardId'>
            <a-select v-model="modalFormData.shopBankCardId" placeholder="请选择" style="min-width: 120px">
              <a-select-option v-for="item in bankCardList" :key="item.shopBankCardId" :value="item.shopBankCardId">
                {{item.bankName}}（{{item.bankCardNo.substr(-4)}}）
              </a-select-option>
            </a-select>
            <a-button type='link' style='padding:0' @click='$router.push("/capital/bank_card")'>添加银行卡</a-button>
          </a-form-model-item>
          <a-form-model-item v-if="shopMainData" label="短信验证码：" prop='validCode'>
            <sms-code ref='smsCode' :type="3" :phone='shopMainData.orginPhone' @isGeted='(res)=>{isGetSms = res}' v-model='modalFormData.validCode'></sms-code>
            <p style='font-size:12px;line-height:14px;margin: 4px 0 12px'>验证码将发送至店铺主的手机号：{{shopMainData.phone}}</p>
          </a-form-model-item>
          <a-form-model-item label="备注：" prop="remark">
            <a-input v-model="modalFormData.remark" allowClear :autoSize="{minRows:4}" placeholder="请输入备注" type="textarea" />
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>
<script>
import NP from 'number-precision'
import { capital } from '@/api'
import { onMounted, reactive, toRefs, ref } from '@vue/composition-api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'ShopInfo',
  setup (props, { root }) {
    const labelCol = { span: 5 }
    const wrapperCol = { span: 17 }
    const rules = {
      cashAmount: [{ required: true, message: '请输入本次提现金额' }],
      shopBankCardId: [{ required: true, message: '请选择银行卡' }],
      validCode: [{ required: true, message: '请输入验证码' }]
    }
    const columns = [
      {
        title: '时间',
        width: 200,
        dataIndex: 'createTime'
      },
      {
        title: '供应商',
        dataIndex: 'name'
      },
      {
        title: '名称',
        width: 320,
        dataIndex: 'eventName'
      },
      {
        title: '订单号',
        dataIndex: 'orderNo',
        width: 200,
        scopedSlots: { customRender: 'orderNo' }
      },
      {
        title: '支付单号',
        dataIndex: 'payOrderNo',
        width: 200,
        scopedSlots: { customRender: 'payOrderNo' }
      },
      {
        title: '已结算金额',
        dataIndex: 'cashAmount'
      },
      {
        title: '待结算金额',
        dataIndex: 'uncashAmount'
      }
    ]
    onMounted(() => {
      getShopAccount()
      getTableData()
    })
    const state = reactive({
      isGetSms: false,
      shopMainData: null,
      headLoading: false,
      authVisible: false,
      authModalLoading: false,
      tableLoading: false,
      authModalConfirmLoading: false,
      shopAccountInfo: {},
      modalFormData: {},
      params: {},
      bankCardList: [],
      modalFormRef: null,
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      formRef: null
    })
    const smsCode = ref(null)
    async function getShopAccount () {
      state.headLoading = true
      let { code, data, msg } = await capital.getShopAccouont({
        shopId: getSession(SHOPID)
      })
      state.headLoading = false
      if (code === '00000') {
        data.amount = NP.round(data.amount || 0, 2).toFixed(2)
        data.cashAmount = NP.round(data.cashAmount || 0, 2).toFixed(2)
        data.uncashAmount = NP.round(data.uncashAmount || 0, 2).toFixed(2)
        data.cashedAmount = NP.round(data.cashedAmount || 0, 2).toFixed(2)
        state.shopAccountInfo = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function goRecordPage () {
      root.$router.push('/capital/balance/record')
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }
    async function withdrawal () {
      state.authVisible = true
      await getMyBankCardList()
      if (!state.shopMainData) {
        state.authModalLoading = true
        let { code, data, msg } = await capital.getShopMaster({
          shopId: getSession(SHOPID)
        })
        state.authModalLoading = false
        if (code === '00000') {
          state.shopMainData = {
            phone: data.phone || data.loginName,
            orginPhone: data.phone || data.loginName,
            md5Str: data.md5Str
          }
          state.shopMainData.phone =
            state.shopMainData.phone.substr(0, 3) + '****' + state.shopMainData.phone.substr(-4)
          await root.$nextTick()
          smsCode.value.clearIntervalFn()
        } else {
          root.$message.error(msg || '获取数据失败')
        }
      } else {
        await root.$nextTick()
        smsCode.value.clearIntervalFn()
      }
    }
    async function getMyBankCardList () {
      state.authModalLoading = true
      let { code, msg, data } = await capital.getBankCardPage({
        shopId: getSession(SHOPID)
      })
      state.authModalLoading = false
      if (code === '00000') {
        state.bankCardList = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function getAll () {
      root.$set(state.modalFormData, 'cashAmount', Number(state.shopAccountInfo.cashAmount))
    }
    async function authFn () {
      state.modalFormRef.validate(async (valid) => {
        if (valid) {
          if (!state.isGetSms) return root.$message.error('请先获取短信验证码')
          state.authModalConfirmLoading = true
          let { code, msg } = await capital.shopCashApply({
            shopId: getSession(SHOPID),
            shopBankCardId: state.modalFormData.shopBankCardId,
            phone: state.shopMainData.orginPhone,
            validCode: state.modalFormData.validCode,
            remark: state.modalFormData.remark,
            md5Str: state.shopMainData.md5Str,
            cashAmount: state.modalFormData.cashAmount
          })
          state.authModalConfirmLoading = false
          if (code === '00000') {
            root.$message.success('申请成功')
            getShopAccount()
            state.authVisible = false
            state.modalFormData = {}
            state.modalFormRef.resetFields()
          } else {
            root.$message.error(msg || '申请失败')
          }
        }
      })
    }
    function changeAmount (res) {
      state.modalFormRef.validateField('cashAmount')
    }
    async function getTableData () {
      state.tableLoading = true
      let { code, data, msg, page } = await capital.getShopAccountPage({
        shopId: getSession(SHOPID),
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.params
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleSearch () {
      state.pagination.current = 0
      getTableData()
    }
    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }
    return {
      columns,
      smsCode,
      rules,
      labelCol,
      wrapperCol,
      ...toRefs(state),
      getShopAccount,
      goRecordPage,
      withdrawal,
      authFn,
      getAll,
      getMyBankCardList,
      changeAmount,
      handleTableChange,
      handleSearch,
      handleReset
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0px;
}
.page-head {
  padding-bottom: 24px;
  .head-item {
    padding: 8px 40px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84px;
    .value {
      font-weight: 500;
      font-size: 24px;
    }
    .label {
      font-size: 14px;
      color: #666;
    }
    &:last-child {
      border-right: 0;
    }
    .withdrawal-box {
      display: flex;
      align-items: center;
      span {
        margin-right: 32px;
      }
    }
  }
}
</style>
